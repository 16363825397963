import React from 'react';
import './App.css';
import profilePhoto from './assets/profile.webp'
import habitTracker from './assets/habit-tracker.jpeg'
import healthcare from './assets/healthcare.jpeg'
import inderse from './assets/inderse.jpeg'
import playpik from './assets/playpik.jpeg'
import quiz from './assets/quiz.jpeg'
import sosyallio from './assets/sosyallio.jpeg'
import watchdock from './assets/watchdock.png'
import watchdockLogo from './assets/watchdock-logo.png'
import dell from './assets/dell.jpeg'
import magfi from './assets/magfi.jpeg'
import satisgaranti from './assets/satisgaranti.png'
import insurance360 from './assets/insurance360.png'
import gamerpazar from './assets/gamerpazar.png'

export default function App() {
  const portfolio = [
    {
      "icon": "https://play-lh.googleusercontent.com/HPZm1XjhwI4y0xS7_23OOKmMiIUmG_koqA9XYatfSn2SdtE1m6cmx3Lirf7REwGfGhI=w480-h960",
      "title": "gamerpazar: Gaming Marketplace",
      "description": "gamerpazar is an innovative gaming marketplace that enables gamers to trade their gaming equipment and virtual assets.",
      "image": gamerpazar,
      "tags": ["Gaming", "Mobile", "Marketplace", "UI/UX", "Flutter"],
      "featured": true,
    },
    {
      "icon": watchdockLogo,
      "title": "Watchdock: Movies & TV Shows",
      "description": "Create & curate your own movie and TV show lists, and share your cinematic journey with friends.",
      "image": watchdock,
      "tags": ["Entertainment", "Mobile", "UI/UX", "Flutter"],
      "featured": false,
    },
    {
      "icon": "https://is3-ssl.mzstatic.com/image/thumb/Purple116/v4/41/a0/2f/41a02f3c-959b-7617-9ed2-3c8144dbf6fd/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/512x512bb.jpg",
      "title": "Magfi: Group Finder",
      "description": "Magfi gathers groups from popular messaging applications like WhatsApp, Telegram, Discord, and Signal under one roof and categorize them based on interests for your convenience.",
      "image": magfi,
      "tags": ["Entertainment", "Mobile", "UI/UX", "Flutter"],
      "featured": false,
    },
    {
      "icon": "https://play-lh.googleusercontent.com/ZivJCQzcxyyom2SdH5UomtRToKUNo9yk4MSWdckjM6iVPdtLOvQMxNWOO2wbVl9HA7zc=w240-h480",
      "title": "Insurance360: Insurance Marketplace",
      "description": "All-in-one insurance application. Compare, choose, and save. Benefit from the best offers. Whether with an advisor or not, it's up to you.",
      "image": insurance360,
      "tags": ["Productivity", "Mobile", "Habit Tracker", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://logowik.com/content/uploads/images/dell-technologies9919.logowik.com.webp",
      "title": "DELL: Make It Real",
      "description": "DELL Forum Istanbul Make It Real mobile application project organized with the participation of thousands of people from the business world and sponsored by globally renowned companies like Microsoft.",
      "image": dell,
      "tags": ["Business", "Mobile", "Swift", "Java"],
      "featured": false,
    },
    {
      "icon": "https://startupmarket.co/cache/100x100/upload/images/startup/620b79db03929288354430.jpg",
      "title": "Sosyallio: Social Media Platform",
      "description": "Discover hundreds of events, courses, and workshops around you, and find the activities that suit you best, allowing you to meet new people through a social platform.",
      "image": sosyallio,
      "tags": ["Social", "Mobile", "Flutter", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://yt3.googleusercontent.com/--zIC-9jWOc7ApPYzgpUD1BighzOGOrJcVKyqEdGsHRvZUHzGM9ZkUgi5mp7sW0Rp5N2Rg8gMg=s900-c-k-c0x00ffffff-no-rj",
      "title": "Satış Garanti: E-commerce Platform",
      "description": "SatisGaranti set out with the goal of swiftly and effortlessly matching those searching for real estate and vehicles with the most relevant and current listings that best meet their needs.",
      "image": satisgaranti,
      "tags": ["Entertainment", "Mobile", "UI/UX", "Flutter"],
      "featured": false,
    },
    {
      "icon": "https://yt3.googleusercontent.com/ytc/APkrFKZGZo_juWgafy72XBaJqfthjDkbprGWxFkigg8u=s176-c-k-c0x00ffffff-no-rj",
      "title": "Inderse: Smart Home Automation",
      "description": "Inderse is a smart home automation application that enables you to control your home appliances remotely.",
      "image": inderse,
      "tags": ["Smart Home", "AI", "Automation", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://www.playpik.com/frontend/img/pvision.webp",
      "title": "Playpik: E-commerce Platform",
      "description": "Playpik is an e-commerce platform that enables you to buy and sell digital products.",
      "image": playpik,
      "tags": ["E-commerce", "Mobile", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://i.pinimg.com/736x/cb/5a/bf/cb5abf2672cea405f38411f1627bf64f.jpg",
      "title": "Moodie: Habits & Mood Tracker",
      "description": "Moodie is a free self-care pet app. You can use it to do mood tracking, habit tracking, self care tracking and activity tracking by choosing your daily mood, activities and taking a mood journal.",
      "image": habitTracker,
      "tags": ["Productivity", "Mobile", "Habit Tracker", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://png.pngtree.com/png-clipart/20200710/original/pngtree-cross-plus-medical-logo-icon-design-template-png-image_4242152.jpg",
      "title": "Healthcare: Expert Consultation",
      "description": "Experienced, qualified doctors and specialists are available 24/7 on our global network. Schedule an appointment with one of our trusted and licensed medical professionals.",
      "image": healthcare,
      "tags": ["Healthcare", "Web", "Consultation", "UI/UX"],
      "featured": false,
    },
    {
      "icon": "https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/ca/25/9d/ca259d72-c747-f27a-155f-feeeaf717d29/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/512x512bb.jpg",
      "title": "Sorunu Sor: Quiz Application",
      "description": "Sorunu Sor is designed for high school and middle school students to solve questions prepared by thousands of expert teachers by loading credits through a mobile application.",
      "image": quiz,
      "tags": ["Education", "Mobile", "Flutter", "UI/UX"],
      "featured": false,
    },
  ]

  return (
    <main className="bg-gray-100 sm:px-16 md:px-24 lg:px-48 xl:px-64 2xl:px-96 py-8 min-h-screen">
      <section className="bg-white rounded-3xl 2xl:p-32 xl:p-24 lg:p-16 md:p-8 sm:p-8 p-4 shadow-xl">
        <div className="flex flex-col items-start">
          <img src={profilePhoto} alt="Semih Aslan" className="w-24 h-24 rounded-xl" />
          <h1 className="text-3xl font-medium text-center mt-4">Hi, I'm Semih!</h1>
          <p className="text-xl mt-2">As a mobile developer and UI/UX designer, you are at the heart of the creative process where innovative ideas are transformed into tangible, user-centric products</p>
          <div className="flex flex-row gap-x-2 mt-4">
            <a href='https://x.com/im_semih' className="text-gray-500" target='blank'>
              <img className='h-8 w-8' alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CjxwYXRoIGQ9Ik0gMTEgNCBDIDcuMTM0IDQgNCA3LjEzNCA0IDExIEwgNCAzOSBDIDQgNDIuODY2IDcuMTM0IDQ2IDExIDQ2IEwgMzkgNDYgQyA0Mi44NjYgNDYgNDYgNDIuODY2IDQ2IDM5IEwgNDYgMTEgQyA0NiA3LjEzNCA0Mi44NjYgNCAzOSA0IEwgMTEgNCB6IE0gMTMuMDg1OTM4IDEzIEwgMjEuMDIzNDM4IDEzIEwgMjYuNjYwMTU2IDIxLjAwOTc2NiBMIDMzLjUgMTMgTCAzNiAxMyBMIDI3Ljc4OTA2MiAyMi42MTMyODEgTCAzNy45MTQwNjIgMzcgTCAyOS45Nzg1MTYgMzcgTCAyMy40Mzc1IDI3LjcwNzAzMSBMIDE1LjUgMzcgTCAxMyAzNyBMIDIyLjMwODU5NCAyNi4xMDM1MTYgTCAxMy4wODU5MzggMTMgeiBNIDE2LjkxNDA2MiAxNSBMIDMxLjAyMTQ4NCAzNSBMIDM0LjA4NTkzOCAzNSBMIDE5Ljk3ODUxNiAxNSBMIDE2LjkxNDA2MiAxNSB6Ij48L3BhdGg+Cjwvc3ZnPg==" />
            </a>
            <a href='https://github.com/devsemih' className="text-gray-500" target='blank'>
              <img className='h-8 w-8' alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CiAgICA8cGF0aCBkPSJNMTcuNzkxLDQ2LjgzNkMxOC41MDIsNDYuNTMsMTksNDUuODIzLDE5LDQ1di01LjRjMC0wLjE5NywwLjAxNi0wLjQwMiwwLjA0MS0wLjYxQzE5LjAyNywzOC45OTQsMTkuMDE0LDM4Ljk5NywxOSwzOSBjMCwwLTMsMC0zLjYsMGMtMS41LDAtMi44LTAuNi0zLjQtMS44Yy0wLjctMS4zLTEtMy41LTIuOC00LjdDOC45LDMyLjMsOS4xLDMyLDkuNywzMmMwLjYsMC4xLDEuOSwwLjksMi43LDJjMC45LDEuMSwxLjgsMiwzLjQsMiBjMi40ODcsMCwzLjgyLTAuMTI1LDQuNjIyLTAuNTU1QzIxLjM1NiwzNC4wNTYsMjIuNjQ5LDMzLDI0LDMzdi0wLjAyNWMtNS42NjgtMC4xODItOS4yODktMi4wNjYtMTAuOTc1LTQuOTc1IGMtMy42NjUsMC4wNDItNi44NTYsMC40MDUtOC42NzcsMC43MDdjLTAuMDU4LTAuMzI3LTAuMTA4LTAuNjU2LTAuMTUxLTAuOTg3YzEuNzk3LTAuMjk2LDQuODQzLTAuNjQ3LDguMzQ1LTAuNzE0IGMtMC4xMTItMC4yNzYtMC4yMDktMC41NTktMC4yOTEtMC44NDljLTMuNTExLTAuMTc4LTYuNTQxLTAuMDM5LTguMTg3LDAuMDk3Yy0wLjAyLTAuMzMyLTAuMDQ3LTAuNjYzLTAuMDUxLTAuOTk5IGMxLjY0OS0wLjEzNSw0LjU5Ny0wLjI3LDguMDE4LTAuMTExYy0wLjA3OS0wLjUtMC4xMy0xLjAxMS0wLjEzLTEuNTQzYzAtMS43LDAuNi0zLjUsMS43LTVjLTAuNS0xLjctMS4yLTUuMywwLjItNi42IGMyLjcsMCw0LjYsMS4zLDUuNSwyLjFDMjEsMTMuNCwyMi45LDEzLDI1LDEzczQsMC40LDUuNiwxLjFjMC45LTAuOCwyLjgtMi4xLDUuNS0yLjFjMS41LDEuNCwwLjcsNSwwLjIsNi42YzEuMSwxLjUsMS43LDMuMiwxLjYsNSBjMCwwLjQ4NC0wLjA0NSwwLjk1MS0wLjExLDEuNDA5YzMuNDk5LTAuMTcyLDYuNTI3LTAuMDM0LDguMjA0LDAuMTAyYy0wLjAwMiwwLjMzNy0wLjAzMywwLjY2Ni0wLjA1MSwwLjk5OSBjLTEuNjcxLTAuMTM4LTQuNzc1LTAuMjgtOC4zNTktMC4wODljLTAuMDg5LDAuMzM2LTAuMTk3LDAuNjYzLTAuMzI1LDAuOThjMy41NDYsMC4wNDYsNi42NjUsMC4zODksOC41NDgsMC42ODkgYy0wLjA0MywwLjMzMi0wLjA5MywwLjY2MS0wLjE1MSwwLjk4N2MtMS45MTItMC4zMDYtNS4xNzEtMC42NjQtOC44NzktMC42ODJDMzUuMTEyLDMwLjg3MywzMS41NTcsMzIuNzUsMjYsMzIuOTY5VjMzIGMyLjYsMCw1LDMuOSw1LDYuNlY0NWMwLDAuODIzLDAuNDk4LDEuNTMsMS4yMDksMS44MzZDNDEuMzcsNDMuODA0LDQ4LDM1LjE2NCw0OCwyNUM0OCwxMi4zMTgsMzcuNjgzLDIsMjUsMlMyLDEyLjMxOCwyLDI1IEMyLDM1LjE2NCw4LjYzLDQzLjgwNCwxNy43OTEsNDYuODM2eiI+PC9wYXRoPgo8L3N2Zz4=" />
            </a>
            <a href='https://www.linkedin.com/in/semihh/' className="text-gray-500" target='blank'>
              <img className='h-8 w-8' alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CiAgICA8cGF0aCBkPSJNNDEsNEg5QzYuMjQsNCw0LDYuMjQsNCw5djMyYzAsMi43NiwyLjI0LDUsNSw1aDMyYzIuNzYsMCw1LTIuMjQsNS01VjlDNDYsNi4yNCw0My43Niw0LDQxLDR6IE0xNywyMHYxOWgtNlYyMEgxN3ogTTExLDE0LjQ3YzAtMS40LDEuMi0yLjQ3LDMtMi40N3MyLjkzLDEuMDcsMywyLjQ3YzAsMS40LTEuMTIsMi41My0zLDIuNTNDMTIuMiwxNywxMSwxNS44NywxMSwxNC40N3ogTTM5LDM5aC02YzAsMCwwLTkuMjYsMC0xMCBjMC0yLTEtNC0zLjUtNC4wNGgtMC4wOEMyNywyNC45NiwyNiwyNy4wMiwyNiwyOWMwLDAuOTEsMCwxMCwwLDEwaC02VjIwaDZ2Mi41NmMwLDAsMS45My0yLjU2LDUuODEtMi41NiBjMy45NywwLDcuMTksMi43Myw3LjE5LDguMjZWMzl6Ij48L3BhdGg+Cjwvc3ZnPg==" />
            </a>
            <a href='mailto:semihaslan305@gmail.com' className="text-gray-500" target='blank'>
              <img className='h-8 w-8' alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CjxwYXRoIGQ9Ik0gMTQgNCBDIDguNDg4NjY2MSA0IDQgOC40ODg2NjYxIDQgMTQgTCA0IDM2IEMgNCA0MS41MTEzMzQgOC40ODg2NjYxIDQ2IDE0IDQ2IEwgMzYgNDYgQyA0MS41MTEzMzQgNDYgNDYgNDEuNTExMzM0IDQ2IDM2IEwgNDYgMTQgQyA0NiA4LjQ4ODY2NjEgNDEuNTExMzM0IDQgMzYgNCBMIDE0IDQgeiBNIDEzIDE2IEwgMzcgMTYgQyAzNy4xOCAxNiAzNy4zNDk3NjYgMTYuMDIwMzEyIDM3LjUwOTc2NiAxNi4wNzAzMTIgTCAyNy42Nzk2ODggMjUuODkwNjI1IEMgMjYuMTk5Njg4IDI3LjM3MDYyNSAyMy43OTA1NDcgMjcuMzcwNjI1IDIyLjMxMDU0NyAyNS44OTA2MjUgTCAxMi40OTAyMzQgMTYuMDcwMzEyIEMgMTIuNjUwMjM0IDE2LjAyMDMxMiAxMi44MiAxNiAxMyAxNiB6IE0gMTEuMDcwMzEyIDE3LjQ5MDIzNCBMIDE4LjU4OTg0NCAyNSBMIDExLjA3MDMxMiAzMi41MDk3NjYgQyAxMS4wMjAzMTIgMzIuMzQ5NzY2IDExIDMyLjE4IDExIDMyIEwgMTEgMTggQyAxMSAxNy44MiAxMS4wMjAzMTIgMTcuNjUwMjM0IDExLjA3MDMxMiAxNy40OTAyMzQgeiBNIDM4LjkyOTY4OCAxNy40OTAyMzQgQyAzOC45Nzk2ODggMTcuNjUwMjM0IDM5IDE3LjgyIDM5IDE4IEwgMzkgMzIgQyAzOSAzMi4xOCAzOC45Nzk2ODcgMzIuMzQ5NzY2IDM4LjkyOTY4OCAzMi41MDk3NjYgTCAzMS40MDAzOTEgMjUgTCAzOC45Mjk2ODggMTcuNDkwMjM0IHogTSAyMCAyNi40MTAxNTYgTCAyMC44OTA2MjUgMjcuMzEwNTQ3IEMgMjIuMDIwNjI1IDI4LjQ0MDU0NyAyMy41MTAyMzQgMjkgMjQuOTkwMjM0IDI5IEMgMjYuNDgwMjM0IDI5IDI3Ljk1OTg0NCAyOC40NDA1NDcgMjkuMDg5ODQ0IDI3LjMxMDU0NyBMIDI5Ljk5MDIzNCAyNi40MTAxNTYgTCAzNy41MDk3NjYgMzMuOTI5Njg4IEMgMzcuMzQ5NzY2IDMzLjk3OTY4OCAzNy4xOCAzNCAzNyAzNCBMIDEzIDM0IEMgMTIuODIgMzQgMTIuNjUwMjM0IDMzLjk3OTY4NyAxMi40OTAyMzQgMzMuOTI5Njg4IEwgMjAgMjYuNDEwMTU2IHoiPjwvcGF0aD4KPC9zdmc+" />
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-y-8 mt-8">
          {portfolio.map((item, index) => (
            <div className="flex flex-col gap-y-4">

              <div className='flex flex-wrap lg:flex-row gap-x-4 gap-y-4 items-center'>
                <img src={item.icon} alt={item.title} className="w-10 h-10 rounded-lg object-contain" />
                <h1 className="text-2xl font-medium">{item.title}</h1>
              </div>

              <p className="text-lg">{item.description}</p>

              <div className='flex flex-wrap gap-2'>
                {
                  item.tags.map((tag, index) => (
                    <span className="text-sm border border-gray-200 text-gray-500 px-2 py-1 rounded-md">{tag}</span>
                  ))
                }
              </div>
              <div className='p-4 lg:p-8 rounded-2xl border border-gray-300 bg-gray-100'>
                <img src={item.image} alt={item.title} className="w-full h-full object-cover rounded-xl" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}
